export interface IRowProps {
    title: string;
    value: string | React.ReactElement;
    comment?: string;
}

function Row({ title, value, comment = '' }: IRowProps): JSX.Element {
    return (
        <div className="flex items-center justify-between p-4 border-b text-p1 border-1 border-blueGray-100 hover:bg-blueGray-100 transition-colors duration-200 in-out">
            <p className="overflow-hidden text-blueGray-500 max-w-1/2 overflow-ellipsis">{title}</p>
            <p className="font-medium text-right text-blueGray-600 max-w-1/2">
                {value}
                {comment && <span className="block mt-2 font-normal">{comment}</span>}
            </p>
        </div>
    );
}

export default Row;
