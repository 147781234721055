import React from 'react';
import Empty from 'epn-ui/umd/dist/components/Empty';
import ItemsSlider from 'epn-ui/umd/dist/components/ItemsSlider';
import Skeleton from 'epn-ui/umd/dist/components/Skeleton';
import useI18n from '@i18n';
import routes from '@config/routes.json';

import BaseCoupon from '@components/base/BaseCoupon';
import BaseGrid from '@components/base/BaseGrid';
import BaseSemanticLink from '@components/base/BaseSemanticLink';
import { ICouponsProps } from '@pages/coupons/interfaces';

const Coupons: React.FC<ICouponsProps> = ({
    coupons,
    isDataLoading,
    isReqHasError,
    onStoreClickHandler,
    onCodeCopyHandler,
}) => {
    const { t } = useI18n();

    return (
        <section className="mb-4 sm:mb-6">
            <div className="flex items-end justify-between mb-6 sm:mb-8">
                <h2 className="font-medium text-blueGray-800 text-h2 sm:text-h1">
                    {t('Promotions')}
                </h2>
                <BaseSemanticLink
                    id="CouponsSeeAllLink"
                    className="text-blueGray-800"
                    to={routes.coupons}
                    target="_blank"
                    rel="nofollow noopener"
                    goalName="wm-dashboard-visit-coupon"
                >
                    {t('See all')}
                </BaseSemanticLink>
            </div>
            <div className="md:hidden">
                <ItemsSlider title="">
                    {coupons.map(coupon => (
                        <BaseCoupon
                            key={coupon.id}
                            url={coupon.url}
                            logo={coupon.logo}
                            endDate={coupon.dateTo}
                            promocode={coupon.promocode}
                            name={coupon.title}
                            description={coupon.description}
                            onStoreClickHandler={onStoreClickHandler}
                            onCodeCopyHandler={onCodeCopyHandler}
                        />
                    ))}
                    {[...new Array(6)].map((item, index) => (
                        <Skeleton
                            key={index}
                            loading={isDataLoading}
                            className="mb-8"
                            shape="square"
                            title={{
                                style: { width: '232px', height: '249px' },
                            }}
                        />
                    ))}
                </ItemsSlider>
            </div>
            <BaseGrid
                container
                className="flex-wrap hidden px-0 pl-0 pr-0 md:flex xs:pr-[8px] xs:pl-[8px]"
            >
                {coupons.map(coupon => (
                    <BaseGrid
                        item
                        key={coupon.id}
                        xl={4}
                        lg={6}
                        md={6}
                        sm={6}
                        className="px-0 pl-0 pr-0 xs:pr-[8px] xs:pl-[8px]"
                    >
                        <BaseCoupon
                            url={coupon.url}
                            logo={coupon.logo}
                            endDate={coupon.dateTo}
                            promocode={coupon.promocode}
                            name={coupon.title}
                            description={coupon.description}
                            onStoreClickHandler={onStoreClickHandler}
                            onCodeCopyHandler={onCodeCopyHandler}
                        />
                    </BaseGrid>
                ))}
                {[...new Array(6)].map((item, index) => (
                    <BaseGrid item key={index} xl={6} lg={6} md={12} sm={12}>
                        <Skeleton
                            loading={isDataLoading}
                            className="mb-8"
                            shape="square"
                            title={{ style: { width: '100%', height: '249px' } }}
                        />
                    </BaseGrid>
                ))}
            </BaseGrid>
            {isReqHasError && <Empty description={t('Failed to load coupons')} />}
        </section>
    );
};

export default Coupons;
