import Row, { IRowProps } from './Row';

export interface ITableProps {
    data: IRowProps[] | null;
}

const Table: React.FC<ITableProps> = ({ data }) => {
    return (
        <div className="flex flex-col -ml-4">
            {Array.isArray(data) &&
                data.map(({ title, value, comment }) => {
                    return (
                        <Row
                            key={`${title}${value}`}
                            title={title}
                            value={value}
                            comment={comment}
                        />
                    );
                })}
        </div>
    );
};

export default Table;
