export interface IIncreasedRateProps {
    value: string;
    oldValue: string;
}

const IncreasedRate: React.FC<IIncreasedRateProps> = ({ value, oldValue }) => {
    return (
        <>
            <span className="line-through mr-[13px]">{oldValue}</span>
            <span className="text-red-400">{value}</span>
        </>
    );
};

export default IncreasedRate;
