import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export interface IItemProps {
    title: string;
    icon: IconDefinition;
    color: string;
}

const Item: React.FC<IItemProps> = ({ title, icon, color }) => {
    return (
        <div className="flex items-baseline">
            <span style={{ color }}>
                <FontAwesomeIcon icon={icon} />
            </span>
            <p className="my-4 ml-3 text-p1 text-blueGray-500 max-w-[255px]">{title}</p>
        </div>
    );
};

export default Item;
