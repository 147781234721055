import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import colors from 'tailwindcss/colors';

import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { faBan } from '@fortawesome/pro-regular-svg-icons/faBan';
import { IGetTrafficSourcesDataFormatted } from '@api/types';

// Components
import DemandGroup from './DemandGroup';

// Helpers
import { IDemandsFilters } from './interfaces';

export interface IDemandsProps {
    demands: {
        allow: number[];
        notAllow: number[];
        needApproval: number[];
    };
    trafficSource: IGetTrafficSourcesDataFormatted[];
}

export interface IFormatedTrafficSource {
    [key: number]: {
        name: string;
    };
}

const DEMANDS_CONFIG = {
    allow: {
        title: 'Allowed',
        icon: faCheckCircle,
        color: colors.green[500],
    },
    notallow: {
        title: 'Forbidden',
        icon: faBan,
        color: colors.red[400],
    },
    needApproval: {
        title: 'Needs approval',
        icon: faCheckCircle,
        color: colors.yellow[400],
    },
};

const PRIORITY = {
    allow: 1,
    needApproval: 2,
    notallow: 3,
};

const Demands: React.FC<IDemandsProps> = ({ demands, trafficSource }) => {
    const filteredDemands: IDemandsFilters = reduce(
        demands,
        (result, value, key) => ({
            ...result,
            ...(!isEmpty(value) && { [key]: value }),
        }),
        {} as IDemandsFilters,
    );

    const demandTypes: string[] =
        Object.keys(filteredDemands).sort((a, b) => PRIORITY[a] - PRIORITY[b]) || [];

    /**
     * @param {IGetTrafficSourcesDataFormatted[]} traffic
     * @returns {IFormatedTrafficSource}
     */
    const getFormatedTrafficSource = (
        traffic: IGetTrafficSourcesDataFormatted[],
    ): IFormatedTrafficSource | null => {
        if (!Array.isArray(traffic)) return null;

        const result: IFormatedTrafficSource = traffic.reduce(
            (table, item) => ({ ...table, [item.id]: { name: item.name } }),
            {},
        );

        return result;
    };

    /**
     * @param {string} type
     * @returns {string[]}
     */
    const getDemandsByType = (type: string): string[] => {
        if (typeof type !== 'string') return [];
        if (!demands[type]) return [];

        const trafficSourceFormat: IFormatedTrafficSource | null = getFormatedTrafficSource(
            trafficSource,
        );
        if (!trafficSourceFormat) return [];

        const result: string[] = demands[type].map(item => trafficSourceFormat[item]?.name);

        return result;
    };

    return (
        <>
            {demandTypes.map(type => {
                return (
                    <DemandGroup
                        key={type}
                        title={DEMANDS_CONFIG[type].title}
                        icon={DEMANDS_CONFIG[type].icon}
                        color={DEMANDS_CONFIG[type].color}
                        data={getDemandsByType(type)}
                    />
                );
            })}
        </>
    );
};

export default Demands;
