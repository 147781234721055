export interface IBannerProps {
    name: string;
    primary: string;
    secondary: string;
    ternary: string;
}

const Banner: React.FC<IBannerProps> = ({ name, primary, secondary, ternary }) => {
    return (
        <div
            className="relative py-6 overflow-hidden text-center rounded-2 hack-h-91px"
            style={{ backgroundColor: primary }}
        >
            <div
                className="absolute top-0 hidden w-[250px] hack-h-95px hack-left-50 transform -translate-x-1/2 xs:block sm:w-[340px] md:w-[500px] hack-offer-banner"
                style={{ backgroundColor: secondary, borderColor: secondary }}
            >
                <div
                    className="absolute z-20 hidden w-10 rounded-full -hack-top-46 -hack-right-94px h-28 transform xs:block md:w-16 md:h-40 md:-hack-top-88 md:-hack-right-108px rotate-45"
                    style={{ backgroundColor: ternary }}
                />
            </div>
            <div className="relative z-10 top-1/2 -translate-y-1/2">
                <p className="font-medium text-white text-sub">{name}</p>
            </div>
        </div>
    );
};

export default Banner;
