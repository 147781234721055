import { useState } from 'react';
import Dialog from 'epn-ui/umd/dist/components/Dialog';
import Tooltip from 'epn-ui/umd/dist/components/Tooltip';
import Button from 'epn-ui/umd/dist/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { faCalendarAlt, faChevronRight, faCopy } from '@fortawesome/pro-regular-svg-icons';

import { copyTextToClipboard } from '@utils/clipboard';
import useI18n from '@i18n';

import BaseToast from '../BaseToast';

export interface IBaseCouponProps {
    url: string;
    logo: string;
    name: string;
    description?: string;
    promocode?: string;
    endDate: string;
    onStoreClickHandler?: () => void;
    onCodeCopyHandler?: () => void;
}

const BaseCoupon: React.FC<IBaseCouponProps> = ({
    url,
    logo,
    name,
    description,
    promocode,
    endDate,
    onStoreClickHandler,
    onCodeCopyHandler,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { t } = useI18n();

    const onCopyMessage = async value => {
        await copyTextToClipboard(value);
        onCodeCopyHandler();
        toast.success(<BaseToast text={t('Promocode copied')} type="success" />, {
            toastId: value,
        });
    };

    return (
        <article className="flex flex-col justify-between mb-8 h-[249px] max-w-[232px] md:max-w-none rounded-2 shadow-1">
            <div className="p-4 pb-0 md:p-6 md:pb-0">
                <div className="h-8 mb-2 box-border max-w-40">
                    <picture>
                        <source type="image/webp" srcSet={`${logo}?f=webp`} />
                        <img
                            src={logo}
                            alt="Logo"
                            loading="lazy"
                            className="h-auto max-w-full max-h-full"
                        />
                    </picture>
                </div>

                {promocode ? (
                    <>
                        <p className="md:mb-1 text-blueGray-500 text-p1">{t('Promocode')}</p>
                        <div className="flex flex-row items-center">
                            <Tooltip id="promocode" place="right">
                                <span
                                    className="font-medium truncate text-promo2"
                                    data-tip={promocode}
                                    data-for="promocode"
                                >
                                    {promocode}
                                </span>
                            </Tooltip>
                            <FontAwesomeIcon
                                icon={faCopy}
                                className="ml-2 cursor-pointer text-blueGray-300 text-h2"
                                onClick={() => onCopyMessage(promocode)}
                                fixedWidth
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <Tooltip id="name" place="right">
                            <p
                                className={`font-medium text-blueGray-800 text-h2 ${
                                    description ? 'truncate' : 'line-clamp-2 '
                                }`}
                                data-tip={name}
                                data-for="name"
                            >
                                {name}
                            </p>
                        </Tooltip>
                        <Tooltip id="description" place="right">
                            <div
                                className="line-clamp-2 text-blueGray-600 text-p1"
                                data-tip={description}
                                data-for="description"
                            >
                                {description}
                            </div>
                        </Tooltip>
                    </>
                )}
            </div>
            <div>
                <div className="relative flex flex-row items-center w-full bg-white">
                    <div className="absolute bg-white rounded-[50%] h-[18px] w-[18px] left-[-9px]" />
                    <div className='relative bg-no-repeat w-[12px] h-[24px] before:content-[""] before:absolute before:w-full before:h-full before:bg-circle rotate-180' />
                    <div className="w-full border-2 border-dashed h-[2px] border-blueGray-300" />
                    <div className='relative bg-no-repeat w-[12px] h-[24px] before:content-[""] before:absolute before:w-full before:h-full before:bg-circle' />
                    <div className="absolute bg-white rounded-[50%] h-[18px] w-[18px] right-[-11px]" />
                </div>
                <div className="flex flex-row items-start justify-between p-6 pt-1">
                    <div className="flex flex-row">
                        <FontAwesomeIcon
                            icon={faCalendarAlt}
                            className="mt-1 mr-2 text-p1 text-blueGray-500"
                            fixedWidth
                        />
                        <div className="flex flex-col">
                            <span className="text-blueGray-500">{t('End day')}</span>
                            <span>{endDate}</span>
                        </div>
                    </div>
                    <button
                        type="button"
                        onClick={() => {
                            setIsOpen(true);
                        }}
                        className="w-12 h-12"
                    >
                        <div className="flex items-center justify-center w-12 h-12 mx-auto mb-4 bg-red-100 rounded-full">
                            <FontAwesomeIcon
                                className="text-red-500 text-p1"
                                icon={faChevronRight}
                            />
                        </div>
                    </button>
                </div>
                <Dialog
                    hasLightOverlay
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    id="coupon_dialog_modal"
                    title={
                        <>
                            <img className="w-auto mb-2 h-9" src={logo} alt={`${name} logo`} />
                            <span className="font-medium text-p1 text-blueGray-800">{name}</span>
                        </>
                    }
                    size="sm"
                >
                    <p className="mb-8 text-p1 text-blueGray-800">{description}</p>
                    <Button.Beta
                        type="primary"
                        onClick={onStoreClickHandler}
                        target="_blank"
                        rel="nofollow noopener"
                        href={url}
                    >
                        {t('Go to store')}
                    </Button.Beta>
                </Dialog>
            </div>
        </article>
    );
};

export default BaseCoupon;
