import useI18n from '@i18n';

export interface ISidebarProps {
    config: {
        key: string;
        link: string;
    }[];
}

const Sidebar: React.FC<ISidebarProps> = ({ config }) => {
    const { t } = useI18n();

    return (
        <div className="flex flex-col">
            {Array.isArray(config)
                ? config.map(({ key, link }) => {
                      return (
                          <a
                              className="w-full p-2 cursor-pointer text-p1 text-blueGray-500 hack-max-w-240px hover:text-blueGray-800 hover:bg-blueGray-100 active:text-blueGray-800 active:bg-blueGray-100 lg:max-w-full"
                              href={`#${link}`}
                              key={link}
                          >
                              {t(key)}
                          </a>
                      );
                  })
                : null}
        </div>
    );
};

export default Sidebar;
