import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { useIntersection } from 'react-use';

import gfm from 'remark-gfm';

import Loader from 'epn-ui/umd/dist/components/Loader';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLevelUp } from '@fortawesome/pro-solid-svg-icons';
import TooltipPro from 'epn-ui/umd/dist/components/TooltipPro';
import { OfferCompilationStatusTypes } from 'src/types.d';

import {
    OFFERS_PARAMS,
    getSortedComission,
    OFFER_FIELDS,
    IComissionItem,
    IIncreasedComissionItem,
} from '@components/pages/offers/[url]/config';

import useRefreshToken from '@utils/hooks/useRefreshToken';
import { AMPLITUDE_EVENTS_KEYS, logAmplitudeEvent } from '@utils/amplitude';
import sendGoal from '@utils/sendGoal';

import * as TYPES from '@api/types';
import apiHooks from '@apiHooks';
import routes from '@config/routes.json';

import useI18n from '@i18n';

import BaseGrid from '@base/BaseGrid';

import Banner from '@components/pages/offers/[url]/Banner';
import Sidebar from '@components/pages/offers/[url]/Sidebar';
import OfferTable from '@components/pages/offers/[url]/Table';
import Deeplink from '@components/pages/offers/[url]/Deeplink';
import Demands from '@components/pages/offers/[url]/Demands';
import OffersList from '@components/pages/offers/[url]/OffersList';
import Coupons from '@components/pages/coupons/Coupons';
import IncreasedRate from '@components/pages/offers/[url]/IncreasedRate';
import BaseCopyableText from '@components/base/BaseCopyableText';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ReactMarkdown = dynamic(() => import('react-markdown'), {
    ssr: true,
});

const { useOfferDetail, useTrafficSources, useOffers, useCoupons } = apiHooks;

const OffersSlugPage: React.FC = () => {
    const { t, locale } = useI18n();
    const router = useRouter();
    const isAuth = useRefreshToken();

    const { url } = router.query;

    const {
        data: offerDetail,
        error: offerDetailError,
        isLoading: isOfferDetailLoading,
    } = useOfferDetail<TYPES.IGetOfferDetailDataFormatted>({
        urlParams: {
            id: url,
        },
        params: {
            lang: locale,
            viewRules: 'role_user',
            fields: OFFER_FIELDS,
        },
        optionalAuth: true,
        condition: !!url,
        dependencies: [url, locale],
    });

    const { data: trafficSourcesData, isLoading: isLoadingTrafficSorces } = useTrafficSources<
        TYPES.IGetTrafficSourcesDataFormatted[]
    >({
        optionalAuth: true,
        dependencies: [locale],
    });

    const {
        id,
        name,
        linkDefault,
        wmDescription,
        primaryColor,
        secondaryColor,
        ternaryColor,
        comission,
        cookieLive,
        wmConfirmTime,
        wmWarningText,
        geoTargetCountry,
        geoTargetComment,
        demands,
        status,
        webmasterMaxRate,
        webmasterMaxRateSymbol,
        logoSmall,
        ordClientFullName,
        ordClientInn,
    } = offerDetail || {};

    const { data: offers, isLoading: isOffersLoading } = useOffers<
        Pick<TYPES.IGetOffersDataFormatted, 'id' | 'url' | 'name' | 'image'>[]
    >({
        params: {
            ...OFFERS_PARAMS,
            lang: locale,
            fields: 'url,name,image',
            typeId: '1',
            limit: 60,
        },
        optionalAuth: true,
        condition: !!url,
        dependencies: [url, locale],
    });

    const { data: coupons, isLoading: isLoadingCoupons, error: couponsErrors } = useCoupons<
        TYPES.IGetCouponsDataFormatted[]
    >({
        params: {
            limit: 6,
            offset: 0,
            lang: locale,
            sort: '-dateFrom',
            offerId: id,
        },
        optionalAuth: true,
        condition: !!id,
        dependencies: [id, locale],
    });

    const onCouponsView = useCallback(() => {
        logAmplitudeEvent(AMPLITUDE_EVENTS_KEYS.offerCouponsView);
    }, []);

    const couponsRef = useRef(null);

    const mockRef = useRef(null);

    const [intersectedOnce, setIntersectedOnce] = useState(false);

    const shouldLogIntersection = intersectedOnce || !coupons;

    const intersection = useIntersection(shouldLogIntersection ? mockRef : couponsRef, {
        root: null,
        rootMargin: '0px',
        threshold: 0.8,
    });

    useEffect(() => {
        if (!intersectedOnce && intersection?.intersectionRatio > 0.8) {
            setIntersectedOnce(true);
            onCouponsView();
        }
    }, [intersection, intersectedOnce, onCouponsView]);

    useEffect(() => {
        setIntersectedOnce(false);
    }, [coupons]);

    useEffect(() => {
        if (isAuth) {
            sendGoal('view_offer_page', 89120816);
        }
    }, [isAuth]);

    const isDataLoading = isLoadingCoupons || isOfferDetailLoading;

    const isReqHasError = !!offerDetailError || !!couponsErrors;

    const formattedCoupons = useMemo(() => {
        return !isDataLoading && !isReqHasError && coupons
            ? coupons.map(coupon => {
                  const { dateTo } = coupon;
                  const dateToObject = dayjs(dateTo);
                  return {
                      ...coupon,
                      logo: logoSmall,
                      dateTo: dateToObject.isValid()
                          ? dateToObject.format('DD.MM.YYYY')
                          : dayjs().format('DD.MM.YYYY'),
                  };
              })
            : [];
    }, [coupons, isDataLoading, isReqHasError, logoSmall]);

    /**
     * если оффер еще не загрузился, то подразумеваем, что он есть
     * если не использовать isOfferDetailLoading, то будет редирект
     */
    const hasOffer =
        !offerDetailError || isOfferDetailLoading || (offerDetail && offerDetail?.id !== undefined);

    // если запрос с оффером вернул пустой объект, то редиректим на страницу с всеми офферами
    useEffect(() => {
        if (!hasOffer || offerDetailError || status === OfferCompilationStatusTypes.disabled) {
            router.push(`/${locale}${routes.offers}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasOffer, offerDetailError, status, locale]);

    // так как мы испольуем ssg, то нужно, чтобы в отредеренных страницах была валидная верстка
    if (!hasOffer || offerDetailError) {
        return null;
    }

    const wmWarningTextConfig = wmWarningText
        ? [
              {
                  key: 'Important',
                  link: 'note',
              },
          ]
        : [];
    const couponsConfig = coupons
        ? [
              {
                  key: 'Promotions',
                  link: 'promotions',
              },
          ]
        : [];
    const deeplinkConfig = isAuth
        ? [
              {
                  key: 'Get deeplink',
                  link: 'deeplink',
              },
          ]
        : [];

    const sidebarConfig = [
        {
            key: 'Description',
            link: 'common',
        },
        ...wmWarningTextConfig,
        ...couponsConfig,
        {
            key: 'Rates',
            link: 'rates',
        },
        {
            key: 'Conditions',
            link: 'conditions',
        },
        {
            key: 'Traffic source requirements',
            link: 'traffic',
        },
        ...deeplinkConfig,
    ];

    const conditionsData = [
        {
            title: t('Cookie life time'),
            value: cookieLive,
        },
        {
            title: t('Order confirmation time'),
            value: wmConfirmTime,
        },
        {
            title: t('Geotargeting'),
            value: geoTargetCountry?.join(', '),
            comment: geoTargetComment,
        },
    ];

    const currentPercent = `${webmasterMaxRate || ''}${webmasterMaxRateSymbol || ''}`;

    const onStoreClickHandler = () => {
        logAmplitudeEvent(AMPLITUDE_EVENTS_KEYS.offerCouponsGoToStore);
    };

    const onCodeCopyHandler = () => {
        logAmplitudeEvent(AMPLITUDE_EVENTS_KEYS.offerCouponsCopyCode);
    };

    const hasIncreasedRate = Array.isArray(comission) && comission.some(elem => elem.oldValue);

    const changeIncreasedRateValues = (rates: IComissionItem[]): IIncreasedComissionItem[] =>
        rates.map(rate => {
            if (rate.oldValue) {
                const newValue = <IncreasedRate value={rate.value} oldValue={rate.oldValue} />;
                return {
                    ...rate,
                    value: newValue,
                };
            }
            return rate;
        });

    const formatIncreasedRate = (rates: IComissionItem[]): IIncreasedComissionItem[] =>
        hasIncreasedRate ? changeIncreasedRateValues(rates) : rates;

    // TODO костыль с бека приходят неправильные данные
    const hideAliOrdData = id === 1;
    const aliOrdData = (
        <>
            <span className="break-all">ООО &quot;АЛИБАБА.КОМ (РУ)&quot;</span> ИНН&nbsp; 7703380158
        </>
    );
    const aliOrdDataCopyable = `ООО "АЛИБАБА.КОМ (РУ)" ИНН  7703380158`;

    return (
        <div className="mb-20">
            <Head>
                <title>
                    {t('affiliate program with up to revenue', {
                        name,
                        percent: currentPercent,
                        currentYear: new Date().getFullYear(),
                    })}
                </title>
                <meta
                    name="description"
                    content={t(
                        'Official affiliate program by ePN with high commission rates up to for affiliates, webmasters and bloggers',
                        {
                            name,
                            percent: currentPercent,
                        },
                    )}
                />

                <meta
                    property="og:title"
                    content={t('Official affiliate program by ePN with profit up to', {
                        name,
                        percent: currentPercent,
                    })}
                />

                <meta
                    property="og:description"
                    content={t(
                        'Official affiliate program by ePN with high commission rates up to for affiliates, webmasters and bloggers',
                        {
                            name,
                            percent: currentPercent,
                        },
                    )}
                />
            </Head>
            <h1 className="mb-8 font-medium text-blueGray-800 text-sub xs:mt-8 xs:text-h2 md:text-h1">
                {t('affiliate program — earn up to from an order', {
                    name,
                    percent: currentPercent,
                })}
            </h1>

            <BaseGrid container className="md:mx-[-1rem]">
                <BaseGrid item md={3} xs={0} className="pr-[16px] pl-[16px] xs:hidden">
                    <aside className="flex-col hidden md:flex">
                        <Sidebar config={sidebarConfig} />
                        <OffersList offers={offers} isLoading={isOffersLoading} />
                    </aside>
                </BaseGrid>

                <BaseGrid item md={9} className="px-0 md:pr-[16px] md:pl-[16px] xs:pl-0 xs:pr-0">
                    {name && (
                        <>
                            <Banner
                                name={name}
                                primary={primaryColor}
                                secondary={secondaryColor}
                                ternary={ternaryColor}
                            />
                            <div className="px-4">
                                <div id="common">
                                    <div className="flex flex-col justify-between my-6 item-baseline xs:flex-row">
                                        <div className="mb-4 font-medium text-blueGray-600 text-sub md:text-h2 xs:mb-0">
                                            {t('Description')}
                                        </div>
                                        <a
                                            className="underline text-blueGray-600 text-p1"
                                            href={linkDefault}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {linkDefault}
                                        </a>
                                    </div>

                                    {ordClientFullName && ordClientInn && (
                                        <TooltipPro title={t('Copy')}>
                                            <div className="inline-flex">
                                                <BaseCopyableText
                                                    copyableText={
                                                        !hideAliOrdData
                                                            ? `${ordClientFullName} ИНН ${ordClientInn}`
                                                            : aliOrdDataCopyable
                                                    }
                                                    className="pr-4 xs:pr-2 max-w-max !text-blueGray-500 bg-emerald-100 rounded-1"
                                                >
                                                    <div className="pl-2">
                                                        {!hideAliOrdData ? (
                                                            <>
                                                                <span className="break-all">
                                                                    {ordClientFullName}
                                                                </span>{' '}
                                                                ИНН&nbsp;
                                                                {ordClientInn}
                                                            </>
                                                        ) : (
                                                            aliOrdData
                                                        )}
                                                    </div>
                                                </BaseCopyableText>
                                            </div>
                                        </TooltipPro>
                                    )}

                                    <ReactMarkdown
                                        remarkPlugins={[gfm]}
                                        components={{
                                            a: props => (
                                                <a
                                                    href={props.href}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {props.children}
                                                </a>
                                            ),
                                        }}
                                        className="hack-markdown-syles"
                                    >
                                        {wmDescription}
                                    </ReactMarkdown>
                                </div>

                                {wmWarningText && (
                                    <div id="note">
                                        <div className="mt-12 mb-5 font-medium text-blueGray-600 text-sub xs:mb-4 md:text-h2 md:mt-16 md:mb-6">
                                            {t('Important')}
                                        </div>
                                        <ReactMarkdown
                                            remarkPlugins={[gfm]}
                                            className="hack-markdown-syles"
                                            components={{
                                                a: props => (
                                                    <a
                                                        href={props.href}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {props.children}
                                                    </a>
                                                ),
                                            }}
                                        >
                                            {wmWarningText}
                                        </ReactMarkdown>
                                    </div>
                                )}
                                <div ref={couponsRef} id="promotions" className="max-w-[90vw]">
                                    {coupons && (
                                        <Coupons
                                            coupons={formattedCoupons}
                                            isDataLoading={isDataLoading}
                                            isReqHasError={isReqHasError}
                                            onStoreClickHandler={onStoreClickHandler}
                                            onCodeCopyHandler={onCodeCopyHandler}
                                        />
                                    )}
                                </div>

                                <div id="rates">
                                    <div className="flex flex-row items-center mt-12 mb-5 font-medium text-blueGray-600 text-sub xs:mb-4 md:text-h2 md:mt-16 md:mb-6">
                                        {t('Rates')}
                                        {hasIncreasedRate && (
                                            <div className="text-red-400 text-p1">
                                                <FontAwesomeIcon
                                                    id="increasedRate"
                                                    className="ml-[16px] mr-[8px]"
                                                    icon={faLevelUp}
                                                />
                                                <span className="font-normal">{t('Raise')}</span>
                                            </div>
                                        )}
                                    </div>
                                    <OfferTable
                                        data={formatIncreasedRate(getSortedComission(comission))}
                                    />
                                </div>

                                <div id="conditions">
                                    <div className="mt-12 mb-5 font-medium text-blueGray-600 text-sub xs:mb-4 md:text-h2 md:mt-16 md:mb-6">
                                        {t('Conditions')}
                                    </div>
                                    <OfferTable data={conditionsData} />
                                </div>

                                <div id="traffic">
                                    <div className="mt-12 mb-5 font-medium text-blueGray-600 text-sub xs:mb-4 md:text-h2 md:mt-16 md:mb-6">
                                        {t('Traffic source requirements')}
                                    </div>
                                    {isLoadingTrafficSorces ? (
                                        <Loader />
                                    ) : (
                                        <Demands
                                            demands={demands}
                                            trafficSource={trafficSourcesData || []}
                                        />
                                    )}
                                </div>
                            </div>
                            {isAuth && (
                                <div id="deeplink">
                                    <Deeplink id={id} />
                                </div>
                            )}
                        </>
                    )}
                </BaseGrid>
            </BaseGrid>
        </div>
    );
};

export default OffersSlugPage;
