import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import useI18n from '@i18n';

import Item from './Item';

export interface IDemandGroupProps {
    title: string;
    icon: IconDefinition;
    color: string;
    data: string[];
}

const DemandGroup: React.FC<IDemandGroupProps> = ({ title, icon, color, data }) => {
    const { t } = useI18n();

    return (
        <div className="border-b border-solid border-blueGray-100 last:border-b-0">
            <p className="mt-4 mb-4 font-medium text-sub leading-p1" style={{ color }}>
                {t(title)}
            </p>
            <div className="flex flex-wrap">
                {data.map(item => {
                    return (
                        <div className="w-full max-w-full xs:max-w-1/2 md:max-w-1/3" key={item}>
                            <Item title={item} icon={icon} color={color} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default DemandGroup;
