import { useRouter } from 'next/router';
import clsx from 'clsx';
import Loader from 'epn-ui/umd/dist/components/Loader';
import * as TYPES from '@api/types';
import { AMPLITUDE_EVENTS_KEYS, logAmplitudeEvent } from '@utils/amplitude';

import routes from '@config/routes.json';

import BaseSemanticLink from '@components/base/BaseSemanticLink';

export interface IOffersListProps {
    offers: Pick<TYPES.IGetOffersDataFormatted, 'id' | 'url' | 'name' | 'image'>[];
    isLoading: boolean;
    className?: string;
}

const OffersList: React.FC<IOffersListProps> = ({ offers, isLoading, className = '' }) => {
    const router = useRouter();

    const { url: routeUrl } = router.query;

    const onRouteChanged = (id: number) => {
        logAmplitudeEvent(AMPLITUDE_EVENTS_KEYS.offerLeftListClick, {
            offer_id: id,
        });
    };

    return (
        <div className={clsx('mt-14', className)}>
            <ul>
                {isLoading && <Loader />}
                {!isLoading &&
                    offers &&
                    offers.map(offer => (
                        <li
                            className={clsx(
                                'text-blueGray-500 text-p1 rounded-2 hover:bg-blueGray-200 transition',
                                {
                                    'bg-blueGray-100': routeUrl === offer.url,
                                },
                            )}
                            key={offer.id}
                        >
                            <BaseSemanticLink
                                className="flex items-center p-2"
                                to={routes.offer.replace(':id', offer.url)}
                                onClick={() => onRouteChanged(offer.id)}
                            >
                                <img className="w-14" src={offer.image} alt={`${offer.url} logo`} />
                                <span className="ml-4">{offer.name}</span>
                            </BaseSemanticLink>
                        </li>
                    ))}
            </ul>
        </div>
    );
};

export default OffersList;
