/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import notification from 'epn-ui/umd/dist/components/Notification';
import { faCopy } from '@fortawesome/pro-regular-svg-icons/faCopy';

import useI18n from '@i18n';

import { copyTextToClipboard } from '@utils/clipboard';

interface IBaseCopyableTextProps {
    label?: string;
    children: React.ReactNode;
    className?: string;
    truncate?: boolean;
    copyableText: string;
}

const BaseCopyableText: React.FC<IBaseCopyableTextProps> = ({
    label,
    children,
    className,
    truncate,
    copyableText,
}) => {
    const { t } = useI18n();

    const onCopyHandler = async () => {
        if (typeof copyableText !== 'string' || copyableText.length === 0) return;
        await copyTextToClipboard(copyableText);
        notification.success({
            message: t('Copied'),
        });
    };

    return (
        <div
            className={clsx(
                'flex items-center text-blueGray-300 hover:!text-blueGray-800 transition-all cursor-pointer',
                className,
            )}
            role="button"
            onClick={onCopyHandler}
        >
            {label && <span className="font-medium text-blueGray-500">{label}:&nbsp;</span>}
            <span aria-hidden="true" className={clsx('hover:cursor-pointer', { truncate })}>
                {children}
            </span>
            <FontAwesomeIcon icon={faCopy} className="ml-2 text-p2" />
        </div>
    );
};

export default BaseCopyableText;
