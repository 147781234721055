import * as TYPES from '@api/types';

export const MAX_OFFERS_COUNT = 1000;

export const OFFERS_PARAMS = {
    viewRules: 'role_user',
    limit: MAX_OFFERS_COUNT,
    offset: 0,
    order: 'priority',
    sortType: 'asc',
    fields: 'url',
    typeId: '1,2',
};

export const OFFER_FIELDS =
    'name,primaryColor,secondaryColor,ternaryColor,link_default,wmDescription,comission,cookieLive,wmConfirmTime,wmWarningText,geoTargetCountry,geoTargetComment,demands,status,webmasterMaxRate,webmasterRateSymbol,logo_small,ordClientFullName,ordClientInn';

export interface IMappedOfferItem {
    params: {
        locale: string;
        url: string;
        id: string;
    };
}

export const PRIVATE_OFFERS: IMappedOfferItem[] = [
    {
        params: { locale: 'ru', url: 'megamarket', id: '135' },
    },
    {
        params: { locale: 'ru', url: 'mvideo', id: '24' },
    },
    {
        params: { locale: 'en', url: 'mvideo', id: '24' },
    },
    {
        params: { locale: 'ru', url: 'marya', id: '1909' },
    },
    {
        params: { locale: 'ru', url: 'edimdoma', id: '2050' },
    },
    {
        params: { locale: 'ru', url: 'eldorado', id: '1259' },
    },
    {
        params: { locale: 'ru', url: 'epnbloggers', id: '91' },
    },
    {
        params: { locale: 'ru', url: 'epnbloggers2', id: '92' },
    },
    {
        params: { locale: 'ru', url: 'ostin', id: '55' },
    },
    {
        params: { locale: 'en', url: 'epnbloggers', id: '91' },
    },
    {
        params: { locale: 'en', url: 'epnbloggers2', id: '92' },
    },
    {
        params: { locale: 'ru', url: 'exness', id: '2120' },
    },
    {
        params: { locale: 'en', url: 'exness', id: '2120' },
    },
    {
        params: { locale: 'ru', url: 'tongames', id: '2144' },
    },
    {
        params: { locale: 'en', url: 'tongames', id: '2144' },
    },
    {
        params: { locale: 'ru', url: 'cryptorevenue', id: '2149' },
    },
    {
        params: { locale: 'en', url: 'cryptorevenue', id: '2149' },
    },
    {
        params: { locale: 'ru', url: 'tonomania', id: '2147' },
    },
    {
        params: { locale: 'en', url: 'tonomania', id: '2147' },
    },
    {
        params: { locale: 'ru', url: 'sportmaster', id: '79' },
    },
    {
        params: { locale: 'ru', url: 'tinkoffdkae', id: '2125' },
    },
];

export const mappedOfferArray = (
    offers: TYPES.IGetOfferDetailData[],
    locale: string,
): IMappedOfferItem[] => {
    if (Array.isArray(offers)) {
        return offers.map(item => {
            return {
                params: {
                    locale,
                    url: item.attributes.url,
                    id: item.id.toString(),
                },
            };
        });
    }

    return [];
};

export const getComissionValue = (str: string): number => {
    if (typeof str !== 'string') return 0;
    if (str.length === 0) return 0;

    return +str.replace(/[^\d|.|,]/g, '');
};

export interface IComissionItem {
    title: string;
    value: string;
    oldValue?: string;
}

export const getSortedComission = (comission: Partial<IComissionItem>[]): IComissionItem[] => {
    if (!Array.isArray(comission)) return [];
    if (comission.length === 0) return [];

    const validComission = comission.filter(item => item.title && item.value);

    return validComission
        .slice()
        .sort(
            (a, b) => getComissionValue(b.value) - getComissionValue(a.value),
        ) as IComissionItem[];
};

export interface IIncreasedComissionItem {
    title: string;
    value: string | React.ReactElement;
    oldValue?: string;
}
