import Button from 'epn-ui/umd/dist/components/Button';
import useI18n from '@i18n';

import routes from '../../../../../config/routes.json';
import Image from './image.png';
import BaseSemanticLink from '../../../../base/BaseSemanticLink';

const oldCabinetLinks = JSON.parse(process.env.NEXT_PUBLIC_OLD_CABINET_LINKS);

export interface IDeeplinkProps {
    id: number;
}

const Deeplink: React.FC<IDeeplinkProps> = ({ id }) => {
    const { t } = useI18n();

    /**
     * @returns {void}
     */
    const saveOfferId = (): void => {
        localStorage.setItem('affiliateLinksOfferId', id.toString());
    };

    return (
        <div className="flex px-4 py-6 mt-16 bg-yellow-50 rounded-2 xs:p-6 sm:py-8 sm:px-6 md:py-5 md:px-8">
            <img className="hidden md:block" src={Image} alt="deeplink" />
            <div className="inline-block ml-0 md:ml-8">
                <div className="mb-4 font-medium text-p1 md:text-h2 md:mt-8 md:mb-6 text-blueGray-600">
                    {t('Get deeplink')}
                </div>
                <div className="mb-6 max-w-[625px] text-p1 lg:mb-8 text-blueGray-500">
                    {t('Deeplink is a so-called partner link generator')}
                </div>
                <div className="flex flex-col xs:flex-row">
                    <BaseSemanticLink to={routes.tools.affiliateLinks}>
                        <Button size="medium" type="primary" onClick={saveOfferId}>
                            {t('Get deeplink')}
                        </Button>
                    </BaseSemanticLink>
                    <BaseSemanticLink
                        className="flex items-center mt-4 ml-0 underline xs:ml-8 xs:mt-0 align"
                        to={`${oldCabinetLinks.help}/tools`}
                    >
                        {t('About')}
                    </BaseSemanticLink>
                </div>
            </div>
        </div>
    );
};

export default Deeplink;
